import { DownloadIcon } from "@heroicons/react/outline";
import Image from "next/image";
import React from "react";
import { downloadQRCode } from "../ReviewDrawer/utils";

const ReviewDrawerQrWrapper = ({ qrUrl }: { qrUrl: string }) => {
  return (
    <>
      <div className="w-full p-2.5 bg-blue-50 rounded-lg flex-col inline-flex">
        <div className="flex flex-col items-center justify-center">
          <div className="self-stretch flex-col justify-center items-center flex">
            <div className="text-gray-900 text-h2-medium">Scan QR Code</div>
            <div className="text-gray-500 text-sm-normal">
              Please scan to leave a review
            </div>
          </div>
          <div className="justify-center items-center align-middle pt-2.5 flex flex-col w-full">
            <div className="relative w-40 h-40 p-5 bg-white rounded-md shadow border border-gray-100 justify-center items-center flex">
              <Image
                src={qrUrl}
                layout={"fill"}
                alt=""
                className="object-cover rounded-full transform scale-x-110 scale-y-110"
                priority={true}
              />
            </div>
            <div
              className="w-full justify-center items-center pt-2.5 inline-flex"
              onClick={() => {
                downloadQRCode(qrUrl);
              }}
            >
              <DownloadIcon className="w-6 h-6 text-blue-600" />
              <div className="text-blue-600 text-base font-medium font-['Inter'] leading-normal">
                Download
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewDrawerQrWrapper;
