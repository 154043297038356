import React, { useState, useEffect } from "react";
import { IGalleryImageAssetProps } from "./types";
import ViewAssetModal from "../ViewAssetModal/ViewAssetModal";

const GalleryImageAsset = ({
  media,
  className,
  willOpenViewModal,
}: IGalleryImageAssetProps) => {
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    if (media?.photo) {
      const { photo } = media;
      setImageSrc(photo);
    }
    if (!media?.photo && media?.data) {
      const { data } = media;
      setImageSrc(data);
    }
  }, [media]);

  return (
    <>
      {imageSrc && (
        <>
          <img
            src={imageSrc}
            className={className}
            onClick={() => setViewModalOpen(true)}
          />
          {willOpenViewModal && (
            <ViewAssetModal
              open={viewModalOpen}
              setOpen={setViewModalOpen}
              asset={imageSrc}
            />
          )}
        </>
      )}
    </>
  );
};

GalleryImageAsset.defaultProps = {
  className:
    "object-cover border-none cursor-pointer rounded-md w-60 sm:h-24 h-20 shadow",
  media: {},
} as IGalleryImageAssetProps;

export default GalleryImageAsset;
