import { useCallback, useState } from "react";
import { ShareResult, ShareStatus } from "./types";

const useNavigatorShare = () => {
  const [shareResult, setShareResult] = useState<ShareResult>({
    status: ShareStatus.Idle,
    error: null,
  });

  const isShareSupported = "share" in navigator;

  const share = useCallback(
    async (url: string, title = "", text = "") => {
      if (isShareSupported) {
        try {
          await navigator.share({
            title,
            text,
            url,
          });
          setShareResult({ status: ShareStatus.Success, error: null });
        } catch (error) {
          setShareResult({ status: ShareStatus.Error, error: error as Error });
        }
      } else {
        setShareResult({
          status: ShareStatus.Unsupported,
          error: "Share not supported on this browser. Please copy the link.",
        });
      }
    },
    [isShareSupported]
  );

  return { share, shareResult, isShareSupported };
};

export default useNavigatorShare;
