import React from "react";
import useNavigatorShare from "../../hooks/useNavigatorShare/useNavigatorShare";
import { ShareWrapperProps } from "./type";

const ShareWrapper: React.FC<ShareWrapperProps> = ({
  title,
  text,
  url,
  postShareCallback,
  children,
}) => {
  const { share, shareResult } = useNavigatorShare();

  const handleShare = () => {
    share(url, title, text);
    postShareCallback && postShareCallback(shareResult);
  };

  return <div onClick={handleShare}>{children}</div>;
};

export default ShareWrapper;
