import { InformationCircleIcon, LinkIcon } from "@heroicons/react/outline";
import React, { useContext } from "react";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";

const ReviewLinkDropDown = ({
  currentDropdownValue,
  setCurrentDropdownValue,
  dropdownItems,
  setDropdownItems,
  setShowReviewLinkDropDown,
}: any) => {
  const { basicInfo } = useContext(DesktopContext);
  const isGoogleReviewLinkPresent = dropdownItems.find(
    (item: any) => item === "Google"
  );

  const isFacebookReviewLinkPresent = dropdownItems.find(
    (item: any) => item === "Facebook"
  );
  const isOtherReviewLinkPresent = dropdownItems.find(
    (item: any) => item === "Other"
  );

  const handleOptionSelection = (option: any) => {
    setCurrentDropdownValue(option);
    setShowReviewLinkDropDown(false);
  };
  return (
    <>
      <div className=" absolute w-80  shadow-lg rounded-lg top-10 -left-12 z-20 bg-white border border-gray-300">
        <div className="w-80 h-60 py-1 border-b border-gray-200 flex-col justify-start items-start inline-flex">
          {isGoogleReviewLinkPresent && (
            <div
              className="self-stretch h-20 px-6 py-3.5 border-b border-gray-200 flex-col justify-start items-start gap-1 flex"
              onClick={() => {
                handleOptionSelection("Google");
              }}
            >
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="py-0.5 justify-start items-start gap-2.5 flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    <img
                      className="w-6 h-6"
                      src="/google-logo.png"
                      alt="Google Logo"
                    />
                  </div>
                </div>
                <div className="flex-col justify-center items-start inline-flex">
                  <div className="justify-start items-center gap-1 inline-flex ">
                    <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
                      Google
                    </div>
                    <div className="px-2.5 py-0.5 bg-blue-100 rounded-lg justify-center items-center gap-0.5 flex">
                      <div className="text-center text-blue-800 text-xs font-medium font-['Inter'] leading-none">
                        Recommended
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-500 text-sm max-w-200 font-normal font-['Inter'] leading-tight truncate">
                    {basicInfo?.review_link}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFacebookReviewLinkPresent && (
            <div
              className="self-stretch h-20 px-6 py-3.5 border-b border-gray-200 flex-col justify-start items-start gap-1 flex"
              onClick={() => {
                handleOptionSelection("Facebook");
              }}
            >
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="py-0.5 justify-start items-start gap-2.5 flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    <img
                      className="w-6 h-6"
                      src="/facebook-logo.png"
                      alt="Facebook Logo"
                    />
                  </div>
                </div>
                <div className="flex-col justify-center items-start inline-flex">
                  <div className="justify-start items-center gap-1 inline-flex ">
                    <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
                      Facebook
                    </div>
                  </div>
                  <div className="text-gray-500 max-w-200 text-sm font-normal font-['Inter'] leading-tight truncate">
                    {basicInfo?.leave_review_link_3}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isOtherReviewLinkPresent && (
            <div
              className="self-stretch h-20 px-6 py-3.5 flex-col justify-start items-start gap-1 flex"
              onClick={() => {
                handleOptionSelection("Other");
              }}
            >
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="py-0.5 justify-start items-start gap-2.5 flex">
                  <div className="w-6 h-6 py-0.5 bg-blue-100 rounded-full justify-center items-center gap-2.5 flex">
                    <LinkIcon className="w-4 h-4 text-blue-600" />
                  </div>
                </div>
                <div className="flex-col justify-center items-start inline-flex">
                  <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
                    Other
                  </div>
                  <div className="text-gray-500 max-w-200 text-sm font-normal font-['Inter'] leading-tight truncate">
                    {basicInfo?.review_link_more}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full h-9 px-6 py-2 bg-gray-50 justify-start items-center gap-2 inline-flex  rounded-b-lg">
          <div className="justify-start items-start align-middle  gap-2 flex">
            <div>
              <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content={"tooltipText"}
                data-tooltip-place="top"
              >
                <InformationCircleIcon
                  className="flex w-5 h-5 text-gray-500 cursor-pointer"
                  style={{ strokeWidth: 1.5 }}
                />
              </a>
            </div>
            <div className="flex-col justify-center items-start inline-flex align-middle text-center">
              <div className="text-gray-700 text-xs mt-0.5 font-normal font-['Inter'] leading-tight">
                Edit review links in Settings &gt; Reviews
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewLinkDropDown;
