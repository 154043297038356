import React, { useContext } from "react";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import UnSubscribedEmptyState from "../UnSubscribedEmptyState/UnSubscribedEmptyState";

export default function UnsubscribedEmptyStateWrapper({ children }: any) {
  const { profileInfo } = useContext(DesktopContext);

  function renderChildren() {
    return (
      <>
        {profileInfo && !profileInfo?.slug ? (
          <UnSubscribedEmptyState />
        ) : (
          <>{children}</>
        )}
      </>
    );
  }

  return renderChildren();
}
