import { LocationMarkerIcon, StarIcon } from "@heroicons/react/solid";
import React, { useContext } from "react";

import Bar from "../../../uiwrappers/Bar/Bar";
import { convertToShortFormat } from "../Insight/utils";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";

const LeaderBoardItem = ({
  competitor,
  isFirst,
  highestReviewCount,
}: {
  competitor: any;
  isFirst: boolean;
  highestReviewCount: number;
}) => {
  const { isGmbAuthenticated } = useContext(DesktopContext);

  const barWidth = isFirst
    ? "132"
    : Math.round(
        (parseInt(competitor.reviews_cnt) * 132) / highestReviewCount
      ).toString();

  return (
    <div
      className={`sm:flex justify-start w-full ${
        competitor?.my_page
          ? "text-body-medium text-gray-900"
          : "text-body-normal text-gray-500"
      } items-center justify-between sm:flex-row flex-col sm:space-y-0 sm:mb-0 space-y-1 pb-1 pl-5 sm:ml-0`}
    >
      <div
        className={`text-xs-normal text-gray-700 pr-3 flex items-center w-1/2 sm:max-w-300`}
      >
        {competitor.my_page && (
          <LocationMarkerIcon
            className="h-5 w-5 text-red-500 -ml-6 mr-1"
            style={{ transform: "rotate(270deg)" }}
          />
        )}
        <div
          className={`truncate ${
            competitor?.my_page ? "text-xs-medium" : "text-xs-normal"
          }`}
        >
          {competitor.title}
        </div>
      </div>

      <div className={`flex sm:w-1/2 w-full items-center`}>
        <div className="flex flex-shrink-0 ">
          {competitor?.rating && (
            <span className="w-7">
              {parseFloat(competitor?.rating).toFixed(1)}
            </span>
          )}
        </div>

        <div>
          <StarIcon className="w-5 h-5 text-amber-500" />
        </div>

        <div className="flex items-center gap-1">
          <div
            className={`${
              competitor?.my_page ? "text-blue-600" : "text-blue-100"
            } items-center  -ml-1`}
          >
            <Bar width={barWidth} />
          </div>

          <div className="">
            {isGmbAuthenticated && convertToShortFormat(competitor.reviews_cnt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardItem;
