import React from "react";
import GalleryImageAsset from "../GalleryImageAsset/GalleryImageAsset";
import GalleryVideoAsset from "../GalleryVideoAsset/GalleryVideoAsset";

import { IGalleryAssetProps } from "./types";
import { isMediaTypeVideo } from "./utils";

const GalleryAsset = ({
  media,
  className,
  willOpenViewModal = false,
}: IGalleryAssetProps) => {
  const isMediaAVideoAsset = isMediaTypeVideo(media);
  return (
    <>
      {isMediaAVideoAsset ? (
        <GalleryVideoAsset
          media={media}
          className={className}
          willOpenViewModal={willOpenViewModal}
        />
      ) : (
        <GalleryImageAsset
          media={media}
          className={className}
          willOpenViewModal={willOpenViewModal}
        />
      )}
    </>
  );
};

export default GalleryAsset;
