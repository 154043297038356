import React from "react";
import EmptyState from "../../uiwrappers/EmptyState/EmptyState";

const UnSubscribedEmptyState = () => {
  return (
    <>
      <EmptyState
        header="Check back soon"
        subheader="We’ll update this tab once we’ve launched your new website."
        icon="clock"
      />
    </>
  );
};

export default UnSubscribedEmptyState;
