import { LinkIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";

const ReviewDrawerHeader = ({ currentDropdownValue }: any) => {
  if (currentDropdownValue === "Google") {
    return (
      <>
        <img className="w-6 h-6" src="/google-logo.png" alt="Google Logo" />
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          Google Review Link
        </div>
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          <ChevronDownIcon className="w-5 h-5 " />
        </div>
      </>
    );
  }

  if (currentDropdownValue === "Facebook") {
    return (
      <>
        <img className="w-6 h-6" src="/facebook-logo.png" alt="Google Logo" />
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          Facebook Review Link
        </div>
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          <ChevronDownIcon className="w-5 h-5 " />
        </div>
      </>
    );
  }
  if (currentDropdownValue === "Other") {
    return (
      <>
        <div className="py-0.5 justify-start items-start gap-2.5 flex">
          <div className="w-6 h-6 py-0.5 bg-blue-100 rounded-full justify-center items-center gap-2.5 flex">
            <LinkIcon className="w-4 h-4 text-blue-600" />
          </div>
        </div>
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          Other Review Link
        </div>
        <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">
          <ChevronDownIcon className="w-5 h-5 " />
        </div>
      </>
    );
  }
  return null;
};

export default ReviewDrawerHeader;
