import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";

export interface AlertProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alertMessage: string;
  customStyle?: string;
  leadingIcon?: string;
}

export default function Alert({
  alertMessage,
  customStyle,
  leadingIcon,
}: AlertProps) {
  let icon;
  switch (leadingIcon) {
    case "exclaimation":
      icon = <ExclamationCircleIcon className="w-4 h-4 mr-1" />;
  }
  return (
    <>
      <div
        className={`text-red-600 text-sm-normal ${customStyle} flex items-center`}
        data-cy="error-message"
      >
        {leadingIcon && icon}
        {alertMessage}
      </div>
    </>
  );
}
