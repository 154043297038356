import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import Insight from "../Insight/Insight";

export default function ReviewContainer({
  fromHomePage,
  fromReviewsPage,
}: any) {
  return (
    <UnsubscribedEmptyStateWrapper>
      <div
        className={
          fromHomePage
            ? ""
            : `bg-white mb-4 sm:p-6 py-4 px-6 text-sm-normal text-gray-900 rounded-lg shadow mt-4 w-fit sm:self-start`
        }
      >
        <Insight
          fromHomePage={fromHomePage}
          fromReviewsPage={fromReviewsPage}
        />
      </div>
    </UnsubscribedEmptyStateWrapper>
  );
}
