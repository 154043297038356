import { BasicInfoProps, IReviewLink } from "../../contexts/types";
import { DropdownItems, dropdownItemsFieldMap } from "./types";
import { api } from "../../helpers/topline-api";

export async function downloadQRCode(currentQrUrl: string) {
  const imageResponse = await api.get(currentQrUrl, {
    responseType: "blob",
  });
  const imageBlob = imageResponse.data;
  const url = window.URL.createObjectURL(imageBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${"qrValue"}.png`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
}

export const getDropdownItemsFromMap = (
  basicInfo: BasicInfoProps
): DropdownItems[] => {
  return Object.entries(dropdownItemsFieldMap)
    .filter(([, v]) => basicInfo[v as keyof BasicInfoProps])
    .map(([k]) => DropdownItems[k as keyof typeof DropdownItems]);
};

export const getCustomReviewLinks = (
  reviewLinks: IReviewLink[]
): DropdownItems[] => {
  return reviewLinks
    .filter((link) => link && link.link)
    .map((link) => link.link as DropdownItems);
};
