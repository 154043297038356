import React, { useState } from "react";
import { getMediaType } from "../GalleryAsset/utils";
import { IGalleryVideoAssetProps } from "./types";
import { VideoCameraIcon } from "@heroicons/react/solid";
import ViewAssetModal from "../ViewAssetModal/ViewAssetModal";

const GalleryVideoAsset = ({
  media,
  className,
  willOpenViewModal,
}: IGalleryVideoAssetProps) => {
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const { photo = "", data = "" } = media || {};
  const source = photo || data;
  return (
    <>
      <div
        className="relative w-full h-full cursor-pointer"
        onClick={() => setViewModalOpen(true)}
      >
        <video
          src={`${source}#t=1`}
          muted
          loop
          playsInline
          className={className}
        >
          <source type={getMediaType(media)} />
        </video>
        <VideoCameraIcon className="flex absolute left-1 bottom-1 text-white w-6 h-6" />
      </div>
      {willOpenViewModal && (
        <ViewAssetModal
          open={viewModalOpen}
          setOpen={setViewModalOpen}
          asset={source}
        />
      )}
    </>
  );
};

GalleryVideoAsset.defaultProps = {
  className:
    "object-cover border-none cursor-pointer rounded-md w-60 sm:h-24 h-20 shadow",
  media: {},
} as IGalleryVideoAssetProps;

export default GalleryVideoAsset;
