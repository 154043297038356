import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import Alert from "../../uiwrappers/Alert/Alert";

interface GenericTextAreaProps {
  name: any;
  value: any;
  handleLocalEdits?: any; // either handleLocalEdits or handleChange
  handleChange?: any;
  placeholder?: any;
  edit?: any;
  validateInputValue?: any;
  isPhone?: any;
  editStyle?: any;
  getStyleFromSize?: any;
  customInputPadding?: any;
  customStyle?: any;
  textAreaStyle?: any;
  validationOn?: boolean;
  rows?: number;
  size?: string;
  autoFocus?: boolean;
  onSubmit?: any;
  enterToSubmit?: any;
  required?: boolean;
  externalError?: string;
}

export default function GenericTextArea({
  name,
  value,
  rows,
  placeholder,
  validationOn,
  validateInputValue,
  handleLocalEdits,
  handleChange,
  isPhone,
  customStyle,
  textAreaStyle,
  edit,
  size,
  autoFocus,
  onSubmit,
  enterToSubmit,
  required = false,
  externalError = "",
}: GenericTextAreaProps) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const input = document.getElementById(name);
    if (autoFocus) input?.focus();
  }, []);

  useEffect(() => {
    if (value) setErrorMessage("");
  }, [value]);

  function moveCaretAtEnd(e: any) {
    const temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }
  const cssStyle = {
    paddingLeft: "13px",
    paddingRight: "13px",
    paddingTop: "9px",
    paddingBottom: "9px",
  };

  function onBlurHandler() {
    if (required) {
      if (!value) {
        setErrorMessage("Required");
      } else setErrorMessage("");
    }
    if (
      validationOn &&
      value !== "" &&
      name !== "facebook" &&
      name !== "review_link"
    ) {
      validateInputValue && validateInputValue();
    }
  }

  const onChangeHandler = handleLocalEdits
    ? (e) => {
        handleLocalEdits({
          [name]: `${
            isPhone && /(.?\d){4,}/.test(e.target.value)
              ? new AsYouType("US").input(e.target.value)
              : e.target.value
          }`,
        });
      }
    : handleChange;

  return (
    <form action="#" className="h-full relative">
      <div
        className={`${"my-1 overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500"} ${
          customStyle && customStyle
        } ${
          (errorMessage || externalError) &&
          "border-red-300 bg-green-200 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
        }`}
      >
        <label htmlFor={name} className="sr-only">
          {name}
        </label>
        <textarea
          data-testid="dynamic-textarea"
          rows={rows || 6}
          name={name}
          id={name}
          data-cy={name}
          value={value}
          className={`${
            textAreaStyle
              ? textAreaStyle
              : "block h-full w-full resize-none border-none py-3 placeholder-gray-500 focus:ring-1 text-body-normal"
          } ${size === "lg" && "text-h3-normal"} 
            rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500
         `}
          style={cssStyle}
          placeholder={placeholder}
          defaultValue={""}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          disabled={!edit}
          autoFocus={autoFocus || false}
          onFocus={moveCaretAtEnd}
        />
      </div>
      {(errorMessage || externalError) && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
        </div>
      )}
      <Alert alertMessage={errorMessage} customStyle="text-left mt-1" />
    </form>
  );
}
