export enum ShareStatus {
  Idle = "idle",
  Success = "success",
  Error = "error",
  Unsupported = "unsupported",
}

export interface ShareResult {
  status: ShareStatus;
  error: Error | string | null;
}
