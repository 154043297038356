import React from "react";
import { StarIcon } from "@heroicons/react/solid";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { TrendingUpIcon } from "@heroicons/react/outline";

interface GaugeProps {
  value: number;
  min: number;
  max: number;
  customWidth?: string;
  reviewsNeeded?: number;
  totalReviews: number;
}

export const Gauge = ({
  value,
  min,
  max,
  customWidth,
  reviewsNeeded,
  totalReviews,
}: GaugeProps) => {
  const backgroundArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 1.6)
    .endAngle(Math.PI / 1.6)
    .cornerRadius(1)();

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 1.6, Math.PI / 1.6])
    .clamp(true);

  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 1.6)
    .endAngle(angle)
    .cornerRadius(1)();

  const colorScale = scaleLinear().domain([0, 1]).range(["#dbdbe7", "#4834d4"]);

  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));

  const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.65) / 2);

  return (
    <div className="" style={{ width: customWidth ? customWidth : "6em" }}>
      <svg
        style={{ overflow: "visible" }}
        width={customWidth ? customWidth : "6em"}
        viewBox={[-1, -1, 2, 1].join(" ")}
      >
        <defs>
          <linearGradient
            id="Gauge__gradient"
            gradientUnits="userSpaceOnUse"
            x1="-2"
            x2="2"
            y2="0"
          >
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${index / (gradientSteps.length - 1)}`}
              />
            ))}
          </linearGradient>
        </defs>
        {backgroundArc && (
          <path
            d={backgroundArc}
            fill="#F9FAFB"
            style={{ boxShadow: "inset 0px 0px 10px black" }}
          />
        )}
        {filledArc && <path d={filledArc} fill="#2563EB" />}
        {!isNaN(markerLocation[0]) && (
          <g>
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r="0.16"
              opacity={0.6}
              fill="#3B82F6"
            />
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r="0.13"
              fill="#1E3A8A"
            />
            {value !== max ? (
              <text
                x={markerLocation[0]}
                y={markerLocation[1]}
                textAnchor="middle"
                stroke="white"
                strokeWidth="0"
                fontSize={0.14}
                fontWeight={"bold"}
                fill="white"
                dy=".35em"
              >
                {totalReviews}
              </text>
            ) : (
              <svg
                x={markerLocation[0] - 0.112}
                y={markerLocation[1] - 0.112}
                height={0.22}
                width={0.22}
              >
                <StarIcon fill="white" />
              </svg>
            )}
          </g>
        )}
      </svg>

      <div className="w-4/5 flex flex-col justify-center break-normal text-center -mt-12 -mb-9 mx-auto text-xs">
        {reviewsNeeded == -1 ? (
          <>
            <div className="w-full flex align-center justify-center">
              <img
                src="/TrophyOrange.svg"
                alt="Orange-Trophy"
                className="h-7 w-7"
              />
            </div>
            <div className="font-bold text-gray-600">Congrats!</div>
            <div>You outperformed your competition</div>
          </>
        ) : (
          <>
            <div className="text-3xl font-bold text-blue-600 -mb-1">
              <div className="flex flex-row justify-center items-center">
                {reviewsNeeded}
                <TrendingUpIcon
                  className="ml-1 w-6 h-6 text-blue-900"
                  style={{ strokeWidth: 2.5 }}
                />
              </div>
            </div>
            <div className="font-semibold text-blue-900">MORE REVIEWS</div>
            <div className="text-gray-600 mt-1">
              to outperform your competition
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];
