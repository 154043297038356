import { Global } from "@emotion/react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { URL_CONSTANTS } from "../../constants/urlConstants";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import useNavigatorShare from "../../hooks/useNavigatorShare/useNavigatorShare";
import CenteredDividerWithText from "../CenteredDividerWithText/CenteredDividerWithText";
import ReviewDrawerHeader from "../ReviewDrawerHeader/ReviewDrawerHeader";
import ReviewDrawerQrWrapper from "../ReviewDrawerQrWrapper/ReviewDrawerQrWrapper";
import ReviewDrawerShareTools from "../ReviewDrawerShareTools/ReviewDrawerShareTools";
import ReviewLinkDropDown from "../ReviewLinkDropDown/ReviewLinkDropDown";
import { verifyConnectionStatus } from "../primaryrender/reviews/ReviewSettingsTab/utils";
import { drawerBleeding } from "./constants";
import { DropdownItems, dropdownItemsFieldMap } from "./types";
import { getCustomReviewLinks, getDropdownItemsFromMap } from "./utils";
import { ArrowRightIcon, LinkIcon } from "@heroicons/react/outline";
import ActionModal from "../common/ActionModal/ActionModal";
import { useRouter } from "next/router";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  zIndex: 999,
}));

export default function ReviewDrawer() {
  const {
    basicInfo,
    isGmbAuthenticated,
    isFacebookAuthenticated,
    reviewInfo,
    showQRReviewDrawer,
    setShowQRReviewDrawer,
  } = useContext(DesktopContext);

  const [showReviewLinkDropDown, setShowReviewLinkDropDown] = useState(false);
  const [reviewRequestModalOpen, setReviewRequestModalOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { share } = useNavigatorShare();
  const [dropdownItems, setDropdownItems] = useState<DropdownItems[]>([]);
  const [NoReviewLinkModalOpen, setNoReviewLinkModalOpen] = useState(false);

  const [currentDropdownValue, setCurrentDropdownValue] =
    useState<DropdownItems>(DropdownItems.Other);
  const [currentReviewLink, setCurrentReviewLink] = useState<string>("");
  const [currentQrUrl, setCurrentQrUrl] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    if (dropdownItems?.length === 0) {
      setNoReviewLinkModalOpen(true);
    }
  }, [dropdownItems, showQRReviewDrawer]);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (containerRef.current) {
        if (!containerRef?.current?.contains(event.target as Node)) {
          setShowReviewLinkDropDown(false);
        }
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef]);

  useEffect(() => {
    if (basicInfo && dropdownItemsFieldMap) {
      const field = dropdownItemsFieldMap[currentDropdownValue];
      if (typeof field === "string" || typeof field === "number") {
        const reviewLink = basicInfo[field];

        if (typeof reviewLink === "string" && reviewLink.trim() !== "") {
          setCurrentReviewLink(reviewLink);
        }
      }
    }
  }, [currentDropdownValue, basicInfo, dropdownItemsFieldMap]);

  useEffect(() => {
    const reviewLinkQr = `${URL_CONSTANTS.PROSITE_IMAGE_SRC_QR_URL}?text=${currentReviewLink}`;
    if (reviewLinkQr) {
      setCurrentQrUrl(reviewLinkQr);
    }
  }, [currentReviewLink]);

  useEffect(() => {
    if (!basicInfo) return;

    const fromFieldMap = getDropdownItemsFromMap(basicInfo);
    const customLinks = basicInfo.review_links
      ? getCustomReviewLinks(basicInfo.review_links)
      : [];

    const allLinks: DropdownItems[] = [
      ...fromFieldMap,
      ...customLinks,
      DropdownItems.Other,
    ];

    const reviewArray = verifyConnectionStatus({
      array: allLinks,
      isGmbAuthenticated,
      isFacebookAuthenticated,
    });

    setDropdownItems(reviewArray);
    setCurrentDropdownValue(basicInfo.default_review_link as DropdownItems);
  }, [basicInfo]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setShowQRReviewDrawer(newOpen);
  };

  const hasReviewLinks = dropdownItems.length > 0;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(currentReviewLink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Message will be visible for 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  }, [currentReviewLink]);

  return (
    <>
      {hasReviewLinks && (
        <>
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                overflow: "visible",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              },
            }}
          />

          <SwipeableDrawer
            anchor="bottom"
            open={showQRReviewDrawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            PaperProps={{
              sx: {
                height: `calc(100% - 80px)`,
              },
            }}
            ModalProps={{
              keepMounted: true,
            }}
            transitionDuration={300}
          >
            <div className="flex items-center w-full h-4 py-3">
              <Puller />
            </div>

            <StyledBox
              sx={{
                px: 3,
                backgroundColor: "white",
                height: "100%",
                overflow: "auto",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <div className="flex flex-col h-full justify-start">
                <div className="flex-1 flex flex-col gap-6 justify-start ">
                  <div className="self-stretch flex-col justify-start items-center gap-5 flex">
                    <div className="self-stretch h-full flex-col justify-start items-center gap-4 flex">
                      <div
                        ref={containerRef}
                        className="bg-white rounded-full justify-center items-center gap-2.5 inline-flex relative"
                      >
                        <div
                          className="justify-start items-center gap-2 flex"
                          onClick={() =>
                            setShowReviewLinkDropDown(!showReviewLinkDropDown)
                          }
                        >
                          <ReviewDrawerHeader
                            currentDropdownValue={currentDropdownValue}
                          />
                        </div>
                        {showReviewLinkDropDown && (
                          <ReviewLinkDropDown
                            currentDropdownValue={currentDropdownValue}
                            setCurrentDropdownValue={setCurrentDropdownValue}
                            dropdownItems={dropdownItems}
                            setDropdownItems={setDropdownItems}
                            setShowReviewLinkDropDown={
                              setShowReviewLinkDropDown
                            }
                          />
                        )}
                      </div>
                      {currentQrUrl && (
                        <ReviewDrawerQrWrapper qrUrl={currentQrUrl} />
                      )}
                    </div>

                    <div className="w-full h-14 justify-between items-center gap-4 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
                        <div className="self-stretch text-blue-800 text-sm font-semibold font-['Inter'] uppercase ">
                          Topline Pro Tip
                        </div>
                        <div className="self-stretch text-gray-700 text-sm font-normal font-['Inter'] ">
                          For automated reminders send through our Review
                          Request tool
                        </div>
                      </div>
                      <div
                        className="w-9 h-9 p-2 bg-white rounded-3xl shadow border border-gray-300 justify-center items-center flex"
                        onClick={() => {
                          setReviewRequestModalOpen(true);
                          setShowQRReviewDrawer(false);
                        }}
                      >
                        <ArrowRightIcon className="w-5 h-5 relative" />
                      </div>
                    </div>

                    <CenteredDividerWithText text="or share below" />
                    <div className="justify-center items-start w-full gap-2.5 inline-flex mb-5">
                      <div
                        className="w-full h-9 pl-4 pr-3.5 py-2 bg-white rounded-3xl shadow border border-gray-300 justify-center items-center gap-2 flex"
                        onClick={handleCopyClick}
                      >
                        <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                          {isCopied ? "Copied!" : "Copy"}
                        </div>
                        <LinkIcon className="w-5 h-5 " />
                      </div>
                      <ReviewDrawerShareTools
                        currentReviewLink={currentReviewLink}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="self-stretch pt-4 pb-5 border-t border-gray-200 flex-col justify-start items-center gap-2.5 flex"
                  onClick={() => {
                    setShowQRReviewDrawer(false);
                  }}
                >
                  <div className="self-stretch  justify-center items-start gap-1 inline-flex">
                    <div className="text-gray-600 text-base font-medium font-['Inter'] leading-normal">
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </StyledBox>
          </SwipeableDrawer>
        </>
      )}

      {showQRReviewDrawer && !hasReviewLinks && NoReviewLinkModalOpen && (
        <ActionModal
          open={NoReviewLinkModalOpen}
          setOpen={() => {}}
          hideCancel={false}
          header={"Please connect to Google or Facebook"}
          subheader="Connect to one of these platforms in Settings > Connections or add a custom review link to use this feature."
          primaryButtonText={"Add Connections"}
          primaryButtonFunction={() => {
            router.push("/settings/connections");
            setShowQRReviewDrawer(false);
            setNoReviewLinkModalOpen(false);
          }}
          secondaryButtonFunction={() => {
            setNoReviewLinkModalOpen(false);
            setShowQRReviewDrawer(false);
          }}
          icon="Delete"
        />
      )}
    </>
  );
}
