export enum DropdownItems {
  Google = "Google",
  Facebook = "Facebook",
  Yelp = "Yelp",
  Other = "Other",
}

export const dropdownItemsFieldMap: { [key in DropdownItems]: string } = {
  [DropdownItems.Google]: "review_link",
  [DropdownItems.Facebook]: "leave_review_link_3",
  [DropdownItems.Yelp]: "yelp_review_link",
  [DropdownItems.Other]: "review_link_more",
};
