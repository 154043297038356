import { ShareIcon } from "@heroicons/react/outline";
import React from "react";
import ShareWrapper from "../ShareWrapper/ShareWrapper";
import { toast } from "react-toastify";
import { ShareResult, ShareStatus } from "../../hooks/useNavigatorShare/types";

const ReviewDrawerShareTools = ({
  currentReviewLink,
}: {
  currentReviewLink: string;
}) => {
  return (
    <>
      <div className="w-full">
        <ShareWrapper
          url={currentReviewLink}
          title=""
          text=""
          postShareCallback={(shareResult: ShareResult) => {
            if (shareResult.status == ShareStatus.Unsupported) {
              toast.error(shareResult.error);
            }
          }}
        >
          <div className="w-full h-9 pl-4 pr-3.5 py-2 bg-blue-900 rounded-3xl shadow justify-center items-center gap-2 inline-flex">
            <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
              Share
            </div>
            <ShareIcon className="w-5 h-5 relative text-white" />
          </div>
        </ShareWrapper>
      </div>
    </>
  );
};

export default ReviewDrawerShareTools;
