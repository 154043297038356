import { Review } from "../../../../contexts/types";
import { getAverageRating } from "../ReviewContainer/utils";

export function getReviewsDifference(
  leaderBoardInfo: any[] = [],
  googleReviewsState: any[] = []
) {
  const googleReviewsCount = googleReviewsState.length;
  const leaderboardLength = leaderBoardInfo.length;

  if (
    leaderboardLength === 0 ||
    googleReviewsCount >= leaderBoardInfo[0]?.reviews_cnt
  ) {
    return 0;
  }

  return leaderBoardInfo[0]?.reviews_cnt - googleReviewsCount + 1;
}

export function convertToShortFormat(number: any) {
  number = parseInt(number);
  if (number >= 1000) {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixIndex = Math.floor(Math.log10(number) / 3);
    const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
    return shortNumber + suffixes[suffixIndex];
  }
  return number.toString();
}

export function calculateShowMobileView(
  fromHomePage,
  size,
  isGmbAuthenticated
) {
  return (
    fromHomePage &&
    ((size[0] < 1704 && size[0] > 1276) || size[0] < 811 || !isGmbAuthenticated)
  );
}

export function calculateGoogleReviews(reviewInfo: Review[]) {
  // return all real Google reviews. Placeholder reviews does not have review ids.
  return reviewInfo
    ? reviewInfo.filter(
        (review) => review.source === "Google" && !!review.review_id_from_google
      )
    : [];
}

export function calculateAverageGoogleRating(googleReviews: Review[]) {
  // Implement your getAverageRating logic here
  const rating = getAverageRating(googleReviews, 1);
  // check type of rating and return number
  if (typeof rating === "number") {
    return rating;
  }
  if (typeof rating === "string" && !isNaN(parseFloat(rating))) {
    return parseFloat(rating);
  }
  return 0;
}

export function calculateLeaderBoardInfo(
  reviewInfo,
  competitors,
  averageGoogleRating,
  googleReviews,
  title: string
) {
  // Combine competitors and your information
  const myInfo = {
    title: title,
    rating: averageGoogleRating,
    reviews_cnt: googleReviews?.length,
    my_page: true,
  };

  const competitorsInfoAndMyInfo = [...competitors, myInfo];

  // Sort the array in descending order based on reviews count
  competitorsInfoAndMyInfo.sort((x, y) => y.reviews_cnt - x.reviews_cnt);

  return competitorsInfoAndMyInfo;
}
