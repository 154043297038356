export function verifyConnectionStatus({
  array,
  isGmbAuthenticated = false,
  isFacebookAuthenticated = false,
}: any) {
  const newArray = array.filter((item) => {
    if (item === "Google" && !isGmbAuthenticated) {
      return false;
    }
    if (item === "Facebook" && !isFacebookAuthenticated) {
      return false;
    }
    return true;
  });
  return newArray;
}
