export const defaultLeaderBoardInfo = [
  {
    title: "Competitor",
    rating: "",
    reviews_cnt: "200",
  },
  {
    title: "Your Business Here",
    rating: "",
    reviews_cnt: "180",
    my_page: true,
  },
  {
    title: "Competitor",
    rating: "",
    reviews_cnt: "100",
  },
  {
    title: "Competitor",
    rating: "",
    reviews_cnt: "80",
  },
];
