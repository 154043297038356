import React from "react";

interface CenteredDividerWithTextProps {
  text: string;
}

const CenteredDividerWithText: React.FC<CenteredDividerWithTextProps> = ({
  text,
}) => {
  return (
    <div className="relative h-5 w-full flex items-center">
      <div className="absolute inset-0 flex items-center">
        <div className="flex-grow h-px bg-gray-300"></div>
        <div className="mx-2 text-center text-gray-500 text-sm font-normal font-['Inter'] leading-tight">
          {text}
        </div>
        <div className="flex-grow h-px bg-gray-300"></div>
      </div>
    </div>
  );
};

export default CenteredDividerWithText;
