import React from "react";
export interface BarProps {
  width: string;
  color?: string;
}

export default function Bar({ width, color }: BarProps): React.ReactElement {
  if (width === "0") return <div className="ml-0.5"></div>;
  else
    return (
      <svg
        className="flex items-center h-2"
        style={{ width: parseInt(width) + 3 + "px" }}
      >
        <path
          id="bar"
          d={`M10,0 L${width},0 A1,1 0 1 1 ${width},5 L10,5 A1,1 0 1 1 10,0 z`}
          fill={"currentColor" || color}
        />
      </svg>
    );
}
