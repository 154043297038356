import React from "react";
import PrimaryLink from "../PrimaryLink/PrimaryLink";

function SuggestedEmail({
  name,
  suggestedEmail,
  handleSetSuggestedEmail,
}: any) {
  return (
    <>
      {name === "email" && suggestedEmail && (
        <div className="text-gray-700 text-sm-normal text-left bg-blue-50 p-4 mt-2 rounded-md flex flex-wrap justify-between">
          <span className="flex flex-wrap">
            Did you mean{" "}
            <PrimaryLink
              text={suggestedEmail}
              size="md"
              customStyle="ml-1"
              onClickFunc={handleSetSuggestedEmail}
            />
            ?
          </span>
          <PrimaryLink
            text="Replace"
            size="md"
            onClickFunc={handleSetSuggestedEmail}
          />
        </div>
      )}
    </>
  );
}

export default SuggestedEmail;
