import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useMemo, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { useAuthGoogle } from "../../../../hooks/useAuthGoogle";
import useIsMobile from "../../../../hooks/useIsMobile";
import useWindowSize from "../../../../hooks/useWindowSize";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import { Gauge } from "../../dashboard/Gauge/Gauge";
import { LeaderBoardList } from "../LeaderBoardList/LeaderBoardList";
import {
  calculateAverageGoogleRating,
  calculateGoogleReviews,
  calculateLeaderBoardInfo,
  calculateShowMobileView,
  getReviewsDifference,
} from "./utils";
import Banner from "../../../common/Badge/Banner";

const Insight = ({ fromHomePage, fromReviewsPage }: any) => {
  const isMobile = useIsMobile();

  const {
    competitors,
    profileInfo,
    reviewInfo,
    isGmbAuthenticated,
    setSelectedContacts,
    contactsToRequestReview,
    setReviewRequestModalOpen,
  } = useContext(DesktopContext);
  const slug = profileInfo?.slug;
  const { startGoogleLogin } = useAuthGoogle(slug);
  const [showMore, setShowMore] = useState(true);
  const [reviewsDifference, setReviewsDifference] = useState(0);
  const [gaugeValue, setGaugeValue] = useState(1);
  const size = useWindowSize();

  const showMobileView = useMemo(
    () => calculateShowMobileView(fromHomePage, size, isGmbAuthenticated),
    [fromHomePage, size]
  );
  const googleReviews = useMemo(
    () => calculateGoogleReviews(reviewInfo),
    [reviewInfo]
  );
  const averageGoogleRating = useMemo(
    () => calculateAverageGoogleRating(googleReviews),
    [googleReviews]
  );

  const leaderBoardInfo = useMemo(
    () =>
      calculateLeaderBoardInfo(
        reviewInfo,
        competitors,
        averageGoogleRating,
        googleReviews,
        profileInfo?.company_name
      ),
    [reviewInfo, competitors, averageGoogleRating, googleReviews]
  );

  useEffect(() => {
    if (leaderBoardInfo && leaderBoardInfo.length > 1) {
      getGaugeInfo();
    }
  }, [leaderBoardInfo, googleReviews, isGmbAuthenticated]);

  const listOfContactsToRequestReview = contactsToRequestReview();

  const handleBannerClick = () => {
    setSelectedContacts(listOfContactsToRequestReview);
    setReviewRequestModalOpen(true);
  };

  function getGaugeInfo() {
    const isMyPageFirstOnLeaderBoard = !!leaderBoardInfo[0]?.my_page;
    const isMyPageLastOnLeaderBoard =
      !!leaderBoardInfo[leaderBoardInfo.length - 1]?.my_page;
    const myPage = leaderBoardInfo.filter((pages) => {
      return pages.my_page;
    })[0];

    const reviewNumberDifference = getReviewsDifference(
      leaderBoardInfo,
      googleReviews
    );
    setReviewsDifference(reviewNumberDifference);

    if (myPage.reviews_cnt == 0 || !isGmbAuthenticated) {
      setGaugeValue(0);
      return;
    }
    if (isMyPageFirstOnLeaderBoard) {
      setReviewsDifference(-1);
      setGaugeValue(3);
    } else if (isMyPageLastOnLeaderBoard) {
      setGaugeValue(1);
    } else {
      setGaugeValue(2);
    }
  }

  return (
    <div>
      {leaderBoardInfo && leaderBoardInfo.length && (
        <>
          <div>
            <div className="flex justify-between">
              {!fromHomePage && (
                <div className="text-h3-medium text-gray-900">
                  Google Reviews Leaderboard
                </div>
              )}

              <div
                className={`${
                  (fromHomePage || isGmbAuthenticated) && "hidden"
                }`}
              >
                <PrimaryButton
                  text="Connect"
                  size="md"
                  onClickFunc={startGoogleLogin}
                  customStyle={`self-start`}
                />
              </div>
            </div>
            <div
              className={`flex ${
                fromHomePage && showMobileView
                  ? "flex-col"
                  : "sm:flex-row flex-col"
              }  sm:space-y-0 space-y-6 justify-around md:mx-6 xl:mx-8 my-4 ${
                showMobileView && "sm:flex-col sm:space-y-6"
              }`}
            >
              {isGmbAuthenticated && (
                <div
                  className={`flex flex-col items-center mb-5 md:mb-10 justify-between sm:justify-self-end sm:space-x-0 -ml-1.5 sm:w-200 sm:min-w-200 ${
                    showMobileView &&
                    "flex flex-col items-center mb-1 justify-between"
                  }`}
                >
                  <div>
                    {!fromHomePage && isGmbAuthenticated && (
                      <div className="mb-3 flex">
                        <PrimaryButton
                          text="Connect"
                          size="md"
                          onClickFunc={startGoogleLogin}
                          customStyle={`self-start sm:hidden ml-1 ${
                            isGmbAuthenticated && "hidden"
                          } `}
                        />
                      </div>
                    )}

                    <Gauge
                      value={gaugeValue}
                      min={0}
                      max={3}
                      customWidth={"11rem"}
                      reviewsNeeded={reviewsDifference}
                      totalReviews={googleReviews.length}
                    />
                  </div>
                </div>
              )}

              {isMobile && !!listOfContactsToRequestReview.length && (
                <div
                  className="min-w-200 md:min-w-300 cursor-pointer pt-3"
                  onClick={handleBannerClick}
                >
                  <Banner
                    icon="paper-plane"
                    type="mid-blue"
                    bannerText={`${
                      listOfContactsToRequestReview.length == 1
                        ? `${listOfContactsToRequestReview.length} contact`
                        : `${listOfContactsToRequestReview.length} contacts`
                    }`}
                    trailingText={
                      listOfContactsToRequestReview.length == 1
                        ? `hasn't been sent a request yet!`
                        : `haven't been sent a request yet!`
                    }
                    customStyle="flex-col rounded-full py-1.5"
                    rounded
                  />
                </div>
              )}

              <div
                className={`sm:flex flex-col ${
                  !fromHomePage && "sm:min-w-300"
                }`}
              >
                {!isGmbAuthenticated && (
                  <div className="my-4">
                    <Banner
                      icon="circle-exclaimation"
                      bannerText="Not connected to Google"
                      customWidth="w-full sm:w-auto"
                      subBannerText="Connect to see how your review strength compares to your local competition on Google"
                      rounded
                    />
                  </div>
                )}

                <div className="sm:w-full">
                  <div
                    className="capitalize sm:text-sm-medium text-body-medium text-gray-700 mb-3 flex items-center"
                    onClick={() => isMobile && setShowMore(!showMore)}
                  >
                    {showMore ? (
                      <ChevronUpIcon className="h-5 w-5 mr-1 sm:hidden" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 mr-1 sm:hidden" />
                    )}
                    <div>top local google business profiles</div>
                  </div>
                  <div className={`${!showMore && "hidden"}`}>
                    <LeaderBoardList leaderBoardInfo={leaderBoardInfo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && !!listOfContactsToRequestReview.length && (
            <div
              className={`cursor-pointer ${fromReviewsPage && "xl:hidden"}`}
              onClick={handleBannerClick}
            >
              <Banner
                icon="paper-plane"
                type="mid-blue"
                bannerText={`${
                  listOfContactsToRequestReview.length == 1
                    ? `${listOfContactsToRequestReview.length} contact`
                    : `${listOfContactsToRequestReview.length} contacts`
                }`}
                trailingText={
                  listOfContactsToRequestReview.length == 1
                    ? `hasn't been sent a request yet!`
                    : `haven't been sent a request yet!`
                }
                customStyle="flex-col rounded-full py-1.5"
                rounded
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Insight;
