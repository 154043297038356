import React, { useContext, useEffect, useState } from "react";
import LeaderBoardItem from "../LeaderBoardItem/LeaderBoardItem";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { defaultLeaderBoardInfo } from "./constants";

export function LeaderBoardList({ leaderBoardInfo }: any) {
  const { isGmbAuthenticated } = useContext(DesktopContext);
  const [highestReviewCount, setHighestReviewCount] = useState(0);

  const [displayedLeaderBoardInfo, setDisplayedLeaderBoardInfo] =
    useState(leaderBoardInfo);
  useEffect(() => {
    let displayedLeaderBoardInfoArr;
    if (!isGmbAuthenticated) {
      displayedLeaderBoardInfoArr = defaultLeaderBoardInfo;
    } else displayedLeaderBoardInfoArr = leaderBoardInfo;

    setDisplayedLeaderBoardInfo(displayedLeaderBoardInfoArr);
    setHighestReviewCount(
      parseInt(displayedLeaderBoardInfoArr[0]?.reviews_cnt)
    );
  }, [isGmbAuthenticated, leaderBoardInfo]);

  return (
    <div>
      {displayedLeaderBoardInfo?.map((competitor: any, index: number) => (
        <div key={index}>
          <LeaderBoardItem
            competitor={competitor}
            isFirst={index === 0}
            key={competitor.title + competitor.rating}
            highestReviewCount={highestReviewCount}
          />
        </div>
      ))}
    </div>
  );
}
