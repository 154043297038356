import React from "react";
import { isMediaTypeVideo } from "../GalleryAsset/utils";
import UtilityModal from "../UtilityModal/UtilityModal";
import useIsMobile from "../../../hooks/useIsMobile";
import "react-advanced-cropper/dist/style.css";
import "context-filter-polyfill";

export interface ViewAssetModalProps {
  open: boolean;
  setOpen: (arg1: boolean) => void;
  asset: any;
}

export default function ViewAssetModal({
  open,
  setOpen,
  asset: image,
}: ViewAssetModalProps) {
  const isMobile = useIsMobile();
  const isVideoAsset = isMediaTypeVideo({ photo: image });

  return (
    <UtilityModal
      isOpen={open}
      setIsOpen={setOpen}
      title={isVideoAsset ? "View Video" : "View Photo"}
      onCancelHandler={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      showCancelButton={false}
      showSubmitButton={false}
      width="sm:w-800"
      modalBodyBackground="bg-white"
      disablePadding={isMobile}
    >
      <div className="flex flex-col h-full sm:flex-row sm:-mx-6">
        <div className="flex flex-col w-full items-center justify-center bg-black">
          <div className="flex sm:flex-row sm:space-x-12 w-full h-full justify-center bg-white">
            {!isVideoAsset ? (
              <img src={image} />
            ) : (
              <video
                className="rounded shadow-md w-full h-fit"
                autoPlay
                controls
                muted
                playsInline
              >
                <source src={image} type="" />
              </video>
            )}
          </div>
        </div>
      </div>
    </UtilityModal>
  );
}
