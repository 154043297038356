import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import ShowMoreText from "react-show-more-text";
import { useRouter } from "next/router";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import moment from "moment";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";

export default function FormattedDateAndStarRating({
  review,
  reviewContentStyle,
  fontSize,
  showCreate = false,
}: any) {
  const router = useRouter();
  const [showReviewBody, setShowReviewBody] = useState(false);
  switch (fontSize) {
    case "xs":
      fontSize = "text-xs";
      break;
    case "sm":
      fontSize = "text-sm";
      break;
    case "body":
      fontSize = "text-body";
      break;
    default:
      fontSize = "text-body";
  }

  function toggleReviewBody() {
    setShowReviewBody((prevState) => !prevState);
  }

  function handleCreateShowcase() {
    router.push(`/site/create-showcase?id=${review.id}`);
  }
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between items-start flex-grow">
        <div className={`flex flex-col ${fontSize} w-full`}>
          <div className="font-medium">{review.name}</div>
          <div className="text-gray-600">
            {review?.date_posted &&
              moment(review.date_posted).format("MMM D, YYYY")}
          </div>
          <div className="flex items-center mt-1">
            {(review?.source === "Google" || review?.source === "Facebook") && (
              <div className="rounded-full w-5 h-5 mr-1.5 flex items-center justify-center">
                {review.source === "Google" && (
                  <img src="/google-logo.png" alt="Google Logo" />
                )}
                {review.source === "Facebook" && (
                  <img src="/facebook-logo.png" alt="Facebook Logo" />
                )}
              </div>
            )}
            <div className="flex items-center">
              <StarRatings
                rating={parseFloat(review.rating)}
                isSelectable={false}
                isAggregateRating={true}
                numOfStars={5}
                starRatedColor={"rgb(245,158,11)"}
                starEmptyColor={"rgb(255, 255, 255)"}
                starWidthAndHeight={"18px"}
                starSpacing={"1px"}
              />
            </div>
          </div>

          {reviewContentStyle == "show-more" && review?.review_content && (
            <div className="text-gray-900 mt-2">
              <ShowMoreText
                lines={2}
                more="See more"
                truncatedEndingComponent={"... "}
                anchorClass="cursor-pointer font-medium text-gray-600"
              >
                <p>
                  <span>{review?.review_content}</span>
                </p>
              </ShowMoreText>
            </div>
          )}

          {reviewContentStyle == "truncate" && review?.review_content && (
            <div className="text-gray-900 mt-2 line-clamp-2">
              "{review?.review_content}"
            </div>
          )}
        </div>

        {reviewContentStyle == "chevron-show-more" && (
          <div
            className="flex flex-shrink-0 justify-center items-center w-10 h-10 rounded-full hover:bg-gray-50 p-2"
            onClick={toggleReviewBody}
          >
            {showReviewBody ? (
              <ChevronUpIcon className="w-8 h-8 text-gray-500 cursor-pointer" />
            ) : (
              <ChevronDownIcon className="w-8 h-8 text-gray-500 cursor-pointer" />
            )}
          </div>
        )}
      </div>

      {showReviewBody && review?.review_content && (
        <div className="text-gray-900 mt-2 line-clamp-2">
          "{review?.review_content}"
        </div>
      )}

      {showCreate && (
        <div className="mt-auto pt-4">
          <PrimaryLink
            text="Create Showcase"
            size="sm"
            icon="square-plus"
            onClickFunc={handleCreateShowcase}
            customStyle="text-blue-600"
          />
        </div>
      )}
    </div>
  );
}
